import { Link } from '@remix-run/react';

import { assertExhaustive } from '@lp-lib/game';

import { getFeatureQueryParamString } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import {
  type GlobalPromotion,
  GlobalPromotionStatus,
} from '../../types/promotion';
import { GamePackCover } from '../Game/Utilities';

const Badge = (props: {
  status: GlobalPromotionStatus;
}): JSX.Element | null => {
  let styles = {
    text: '',
    bgColor: '',
  };

  switch (props.status) {
    case GlobalPromotionStatus.New:
    case GlobalPromotionStatus.Configuring:
      styles = {
        text: 'CONFIGURING ...',
        bgColor: 'bg-lp-red-001',
      };
      break;
    case GlobalPromotionStatus.Scheduled:
      styles = {
        text: 'SCHEDULED',
        bgColor: 'bg-blue-005',
      };
      break;
    case GlobalPromotionStatus.Completed:
      styles = {
        text: 'COMPLETED',
        bgColor: 'bg-secondary',
      };
      break;
    default:
      assertExhaustive(props.status);
  }

  return (
    <div
      className={`absolute bottom-0 w-full h-8 rounded-b-lg border border-secondary border-t-0 
        flex items-center justify-center text-white text-base font-bold ${styles.bgColor}`}
    >
      {styles.text}
    </div>
  );
};

const GlobalPromotionCover = (props: {
  promotion: GlobalPromotion;
}): JSX.Element => {
  return (
    <div className='w-60 flex-shrink-0'>
      <GamePackCover pack={null}>
        <Badge status={props.promotion.status} />
      </GamePackCover>
    </div>
  );
};

export const GlobalPromotionSummary = (props: {
  globalPromotion: GlobalPromotion;
}): JSX.Element => {
  const { globalPromotion } = props;

  const tz = useInstance(() => getFeatureQueryParamString('toolkit-tz'));

  return (
    <div className='flex flex-col'>
      <div className='text-2xl font-bold'>{globalPromotion.title}</div>
      <div className='text-base font-normal'>
        {new Date(globalPromotion.startedAt).toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          hour12: true,
          minute: '2-digit',
          timeZone: tz,
          timeZoneName: 'short',
        })}
      </div>
      <div className='text-base'>
        <Link
          to={`/admin/programs/global-promotions/${globalPromotion.id}/promotions`}
          className='underline'
        >
          Orgs: <span className='font-bold'>{globalPromotion.orgsCount}</span>{' '}
        </Link>
      </div>
    </div>
  );
};

export const GlobalPromotionCard = (props: {
  globalPromotion: GlobalPromotion;
  actionSheet: React.ReactNode;
}): JSX.Element => {
  const { globalPromotion: promotion, actionSheet } = props;

  return (
    <div className='w-full bg-dark-gray rounded-xl p-5 flex justify-between'>
      <div className='flex items-center gap-5'>
        <GlobalPromotionCover promotion={promotion} />

        <GlobalPromotionSummary globalPromotion={promotion} />
      </div>
      <div>{actionSheet}</div>
    </div>
  );
};
